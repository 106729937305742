<template>
  <div class="w-full bg-innogreen text-innocream p-10 text-center">
    &copy; {{ currentYear }} Chefpaw LLC<br />This app is protected by US Utility Patent #63/482,925 and PCT/US2024/014261, WO 2024/163901 (tel:2024/163901) A2.
  </div>
</template>

<script setup>
  import { ref } from 'vue'

  const currentYear = ref(new Date().getFullYear())
</script>