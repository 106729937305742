<template>
  <div id="RecipeAdvancedEditForPet" class="w-full overflow-x-auto">

    <div class="container mx-auto pt-10 px6">
      <div class="w-1/6">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToEditRecipe()">
          Back
        </button>
      </div>
    </div>

    <div id="ListPets" class="container mx-auto pt-5 pb-20 px6">
      <div class="text-gray-900 text-3xl text-center">{{ recipe.name }} for {{ pet.name }}</div>
      <div class="bg-innogreen w-full h-1 my-2"></div>
      <div class="text-gray-900 text-xl text-center">This page is intended to be used on a large desktop or iPad screen.</div>
      <div class="text-gray-900 text-xl text-center">{{ pet.name }} should be eating {{ petGramsPerDayRounded }} grams (approx. {{ petCupsPerDayText }}) of food, divided into 2-3 meals per day. That amount of the recipe will result in the following nutritional profile for {{ pet.name }}.</div>
    </div>
    
    <div class="flex min-w-[3200px]">
        <!-- Column 1 -->
        <div v-if="recipe.ingredients.length > 0" class="flex-shrink-0 min-w-[500px] border-l-4 border-y-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left px-2 py-2">
            Recipe Editor
            <button v-if="!saving" @click="editRecipe" type="button" class="ml-2 text-white bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Save Changes &amp; Re-Calculate</button>
            <button v-if="saving" disabled type="button" class="ml-2 text-white bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Processing</button>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                      <tr>
                          <th scope="col" class="px-6 py-3">
                              Ingredient name
                          </th>
                          <th scope="col" class="px-6 py-3">
                              Grams
                          </th>
                          <th scope="col" class="px-3 py-3">
                              Notes
                          </th>
                          <th scope="col" class="px-3 py-3">
                              Action
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Popper :content="txtMaxBatch" :disabled="txtMaxBatch < colMaxChars">
                            {{ $filters.strLimit(txtMaxBatch, colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-6 py-4" :class="{ 'text-red-500': ingredientGramsDontMatchRecipe }">
                          {{ totalDisplay }}
                        </td>
                        <td class="px-3 py-4">
                          &nbsp;
                        </td>
                        <td class="px-3 py-4">
                          &nbsp;
                        </td>
                      </tr>
                      <tr v-for="(ingredient, index) in recipe.ingredients" :key="recipeIngredientKey(recipe, ingredient)"
                        :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <Popper :content="ingredient.name" :disabled="ingredient.name < colMaxChars">
                              {{ $filters.strLimit(ingredient.name, colMaxChars) }}
                            </Popper>
                          </th>
                          <td class="px-6 py-4">
                            <input type="text" class="w-[100px]" v-model="ingredient.amount.total_g" @keyup.enter="editRecipe">
                          </td>
                          <td class="px-3 py-4">
                            <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                            <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                            <span v-if="ingredient.notes.length > 0">
                              <a v-if="ingredient.notes.length > 0" @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                              {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                            </span>
                          </td>
                          <td class="px-3 py-4">
                            <div v-if="!ingredient.loading">
                              <a @click="removeIngredientAtIndex(index)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a>
                            </div>
                            <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="bg-innogreen w-full h-1"></div>

          <div class="text-gray-900 text-xl">Add Ingredients to {{ recipe.name }}</div>
          
          <div class="pt-5 pb-5">
            <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input v-model="query" @keyup.enter="searchIngredients" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ChefPaw Ingredients..." required>
                <button v-if="!searching" @click="searchIngredients" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Search</button>
                <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">
                  <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                    <svg class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"></path>
                    </svg>
                  </div>
                </button>
            </div>
          </div>

          <div v-if="ingredients.length > 0">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-3 py-3">
                                Ingredient name
                            </th>
                            <th scope="col" class="px-2 py-3">
                                Notes
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Grams
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ingredient, index) in ingredients" :key="ingredient.id"
                          :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                            <th scope="row" class="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {{ ingredient.name }}
                            </th>
                            <td class="px-2 py-4">
                              <a v-if="ingredient.possibly_unsafe" @click="showPossiblyUnsafe(ingredient)"><i class="fa-solid fa-triangle-exclamation fa-xl text-innoorange pr-4"></i></a>
                              <span v-if="ingredient.notes.length > 0">
                                <a @click="showIngredientNotes(ingredient)"><i class="fa-solid fa-comment-dots fa-xl text-innogreen pr-4"></i></a>
                                {{ $filters.strLimit(ingredient.notes[0].msg, 12) }}
                              </span>
                            </td>
                            <td class="px-6 py-4">
                              <input type="text" v-model="ingredient.total_g">
                            </td>
                            <td class="px-6 py-4">
                              <div v-if="!ingredient.loading">
                                <a v-if="ingredient.unsafe" @click="showUnsafe(ingredient)"><i class="fa-solid fa-skull-crossbones fa-xl text-red-500 pr-4"></i></a>
                                <a v-if="!ingredient.unsafe && !ingredient.added" @click="addIngredient(ingredient)" href="javascript:void(0)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Add</a>
                                <span v-if="ingredient.added" class="text-green">Added</span>
                              </div>
                              <div v-if="ingredient.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              <div v-if="false" class="flex justify-center items-center">
                                <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
        
        <!-- Column 2 -->
        <div v-if="pet.daily_nutrition_breakdown" class="flex-shrink-0 min-w-[700px] border-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left p-2">
            <div v-if="pets.length > 0 && pet.name != '' && recipe.name != ''" class="mx-2">
              Pet Profile Analyzer
              <select class="
                      appearance-none
                      bg-white
                      text-gray-700
                      border
                      border-gray-400
                      rounded
                      py-3
                      px-4
                      leading-tight
                      focus:outline-none
                      focus:bg-white
                      focus:border-gray-500
                      "
                      v-model="selPetId"
                      @change="selPetChanged()"
              >
                <option v-for="p in pets" :key="p.id" :selected="pet.id == selPetId" :value="p.id">{{ p.name }}</option>
              </select>
            </div>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                    <tr>
                        <th scope="col" class="px-4 py-3">
                            Nutrient
                        </th>
                        <th scope="col" class="px-4 py-3">
                            Daily Need
                        </th>
                        <th scope="col" class="px-4 py-3">
                            Recipe Value
                        </th>
                        <th scope="col" class="px-4 py-3">
                            Nutrient Level
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(nutrient, index) in petNutrients" :key="nutrient.id"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Popper :content="nutrient.name" :disabled="nutrient.name.length < colMaxChars">
                            {{ $filters.strLimit(nutrient.name, colMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Popper :content="nutrient.name" :disabled="nutrient.name.length < colMaxChars">
                            {{ $filters.strLimit(nutrient.name, colMaxChars) }}
                          </Popper>
                        </th>
                        <td class="px-4 py-4">
                          {{ Math.round(nutrient.amount.qty) }}{{ nutrient.amount.unit_type }}
                        </td>
                        <td class="px-4 py-4">
                          {{ Math.round(recipeNutrients[index].amount.qty) }}{{ recipeNutrients[index].amount.unit_type }}
                        </td>
                        <td class="px-4 py-4" :class="[rangeClass(index)]">
                          {{ rangeLabel(index) }}
                        </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Ca/P Ratio
                        </th>
                        <td class="px-4 py-4">
                          1:1 to 2:1
                        </td>
                        <td class="px-4 py-4">
                          {{ formattedRecipeCaPRatio }}
                        </td>
                        <td class="px-4 py-4" :class="[caPRatioClass()]">
                          {{ caPRatioLabel() }}
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>

        <!-- Column 3 -->
        <div v-if="recipe.ingredients.length > 0" class="flex-shrink-0 w-[2000px] border-r-4 border-y-4 border-gray-500">
          <div class="text-gray-900 text-2xl text-left px-2 py-4">Nutrient Yields of Batch</div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[900px]">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                      <tr>
                        <th scope="col" class="px-4 py-3">
                            Nutrient / Data Point
                        </th>  
                        <th scope="col" class="px-4 py-3">
                            <Popper :content="recipe.name" :disabled="recipe.name.length < headersMaxChars">
                              {{ $filters.strLimit(recipe.name, headersMaxChars) }}
                            </Popper>
                        </th>
                        <th scope="col" class="px-4 py-3" v-for="ingredient in recipe.ingredients" :key="ingredientHeaderKey(ingredient)">
                            <Popper :content="ingredient.name" :disabled="ingredient.name.length < headersMaxChars">
                              {{ $filters.strLimit(ingredient.name, headersMaxChars) }}
                            </Popper>
                        </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Total Grams
                      </th>
                      <td scope="row" class="px-4 py-4">
                        {{ sumIngredientsGrams() }}
                      </td>
                      <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientTotalKey(ingredient)">
                        {{ ingredient.amount.total_g }}
                      </td>
                    </tr>  
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">% of recipe</th>
                      <td scope="row" class="px-4 py-4">100%</td>
                      <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientPercentKey(ingredient)">
                        {{ ingredient.amount.percent_of_recipe }}%
                      </td>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Total Dry Matter</th>
                      <td scope="row" class="px-4 py-4">
                        {{ sumTotalGramsDryMatter() }}g
                      </td>
                      <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientGramsDryMatterKey(ingredient)">
                        {{ sumIngredientGramsDryMatter(ingredient)}}g
                      </td>
                    </tr>
                    <tr v-for="(nutrient, index) in recipe.nutrition_breakdown.nutrients" :key="recipeNutrientKey(recipe, nutrient)"
                      :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                        <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                            {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                          </Popper>
                        </th>
                        <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {{ nutrient.name }}
                        </th>
                        <td class="px-4 py-4">
                          {{ nutrient.amount.qty }}{{ nutrient.amount.unit_type }}
                        </td>
                        <td class="px-4 py-4" v-for="ingredient in recipe.ingredients" :key="ingredientBodyKey(ingredient)">
                          {{ ingredientNutrientAmount(ingredient, index) }}{{ ingredient.nutrition_breakdown.nutrients[index].amount.unit_type }}
                        </td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div>
        
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import _ from 'lodash';
  import PetService from '../../services/pet.service.js'
  import RecipeService from '../../services/recipe.service.js'
  import CaPRatioService from '../../services/caPRatio.service.js'
  import Popper from "vue3-popper"
  import IngredientService from '../../services/ingredient.service'
  import Swal from 'sweetalert2'
  import { useToast } from "vue-toastification"

  const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  let pet = ref({name: '', daily_nutrition_breakdown: null})
  let petGramsPerDay = ref('')
  let recipe = ref({ nutrition_breakdown: { total_qty: 2980 }, ingredients: []})
  let petNutrients = ref([])
  let recipeNutrients = ref([])
  let targetDeviations = ref([])
  let nutrientRanges = ref([]) // 1 for low, 2 for optimal, 3 for high
  let caPRatio = ref({low_max: 50, high_min: 150})
  let recipeCaAmount = ref(0)
  let recipePAmount = ref(0)
  let nutrientMaxChars = ref(12)
  let headersMaxChars = ref(20)
  let colMaxChars = ref(20)
  let ingredients = ref([])
  let searching = ref(false)
  let saving = ref(false)
  let errors = ref([])
  let query = ref(null)
  let pets = ref([])
  let txtMaxBatch = ref('MAXIMUM batch size for model#CP3A is 2898 grams')
  let selPetId = ref(route.params.petId)

  onMounted( () => {
    loadAll()
  })

  const recipeCaPRatio = computed({
    get: () => Math.round((recipeCaAmount.value / recipePAmount.value) * 100)
  })

  const formattedRecipeCaPRatio = computed({
    get: () => (recipeCaPRatio.value / 100).toFixed(1) + ':1'
  })

  const ingredientGramsDontMatchRecipe = computed({
    get: () => sumIngredientsGrams() != recipe.value.nutrition_breakdown.total_qty
  })

  const totalDisplay = computed({
      get: () => getTotalGramsText()
  })

  const petGramsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value)
  })

  const petCupsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value / 226)
  })

  const petCupsPerDayText = computed({
      get: () => petCupsPerDayRounded.value == 1 ? petCupsPerDayRounded.value + ' cup' : petCupsPerDayRounded.value + ' cups'
  })

  const loadAll = async () => {
    saving.value = true
    Promise.all([PetService.getPetWithDailyNutritionAndDietaryPerKgNeed(selPetId.value), RecipeService.getRecipeWithNutritionAndHiddenNutrients(route.params.recipeId), getCaPRatio(), getPets()]).then((values) => {
      pet.value = values[0].data.pet
      recipe.value = values[1].data.recipe
      resetData()
    });
  }

  // const loadPet = async (p) => {
  //   try {
  //     let res = await PetService.getPetWithDailyNutritionAndDietaryPerKgNeed(p.id)
  //     pet.value = res.data.pet
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const resetData = () => {
    petNutrients.value = []
    recipeNutrients.value = []
    targetDeviations.value = []
    nutrientRanges.value = []
    petGramsPerDay.value = pet.value.daily_nutrition_breakdown.total_qty
    const recipe_multiplier = petGramsPerDay.value / recipe.value.nutrition_breakdown.total_qty
    for (let i = 0; i < pet.value.daily_nutrition_breakdown.nutrients.length; i++) {
      let nutrient = pet.value.daily_nutrition_breakdown.nutrients[i]
      petNutrients.value.push(nutrient)
      if(!_.find(recipe.value.nutrition_breakdown.nutrients, {id: nutrient.id})) {
        let recipe_nutrient = structuredClone(nutrient)
        recipe_nutrient.amount.qty = 0
        recipeNutrients.value.push(recipe_nutrient)
      }
    }
    for (let i = 0; i < recipe.value.nutrition_breakdown.nutrients.length; i++) {
      let nutrient = structuredClone(recipe.value.nutrition_breakdown.nutrients[i])
      if (nutrient.amount.qty > 0) nutrient.amount.qty = Math.round(nutrient.amount.qty * recipe_multiplier * 1000) / 1000

      // maybe in the future we'll change how this works
      if (nutrient.name == 'Calcium, Ca') recipeCaAmount.value = nutrient.amount.qty
      else if (nutrient.name == 'Phosphorus, P') recipePAmount.value = nutrient.amount.qty

      recipeNutrients.value.push(nutrient)
      if(!_.find(pet.value.daily_nutrition_breakdown.nutrients, {id: nutrient.id})) {
        let pet_nutrient = structuredClone(nutrient)
        pet_nutrient.amount.qty = 0
        petNutrients.value.push(pet_nutrient)
      }
    }
    petNutrients.value = _.sortBy(petNutrients.value, 'default_sort_order')
    recipeNutrients.value = _.sortBy(recipeNutrients.value, 'default_sort_order')
    for (let i = 0; i < petNutrients.value.length; i++) {
      let petNutrient = petNutrients.value[i]
      if (petNutrient.amount.qty == 0) {
        let deviation = 'N/A'
        targetDeviations.value.push(deviation)
        nutrientRanges.value.push(2)
      } else {
        let recipeNutrient = recipeNutrients.value[i]
        let deviation = Math.round((recipeNutrient.amount.qty / petNutrient.amount.qty) * 100)
        let deviationStr = deviation + '%'

        let dpknNutrient = _.find(pet.value.dietary_per_kg_need.nutrition_breakdown.nutrients, function(n) {
          return n.id == recipeNutrient.id
        })

        if (dpknNutrient === undefined) nutrientRanges.value.push(2)
        else if (deviation <= dpknNutrient.amount.low_max) nutrientRanges.value.push(1)
        else if (deviation >= dpknNutrient.amount.high_min) nutrientRanges.value.push(3)
        else nutrientRanges.value.push(2)

        targetDeviations.value.push(deviationStr)
      }
    }
    saving.value = false
  }

  const getTotalGramsText = () => {
    let deviation = recipeIngredientsGramsDeviation()
    if (deviation > 0) return sumIngredientsGrams() + " (" + deviation + " under " + recipe.value.nutrition_breakdown.total_qty + ")"
    else if (deviation < 0) return sumIngredientsGrams() + " (" + (deviation * -1) + " over " + recipe.value.nutrition_breakdown.total_qty + ")"
    else return recipe.value.nutrition_breakdown.total_qty
  }

  const recipeIngredientsGramsDeviation = () => {
    return recipe.value.nutrition_breakdown.total_qty - sumIngredientsGrams()
  }

  const searchIngredients = async () => {
    searching.value = true
    try {
      let res = await IngredientService.searchIngredientsByName(query.value)
      ingredients.value = res.data.ingredients
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const showPossiblyUnsafe = (ingredient) => {
    let msg = "This ingredient is possibly unsafe or some dogs may be allergic.<br>"
    if (ingredient.notes) {
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
    }
    Swal.fire(ingredient.name, msg, 'warning')
  }

  const showIngredientNotes = (ingredient) => {
    if (ingredient.notes) {
      let msg = ''
      for (let i = 0; i < ingredient.notes.length; i++) {
        msg += ingredient.notes[i].msg + "<br>"
      }
      Swal.fire(ingredient.name, msg, 'info')
    }
  }

  const addIngredient = async (ingredient) => {
    let data = new FormData();
    data.append('ingredients', JSON.stringify([{id: ingredient.id, total_g: ingredient.total_g}]))

    try {
      saving.value = true
      let res = await RecipeService.addIngredients(recipe.value.id, data)
      // let res = await RecipeService.getRecipeWithNutrition(recipe.value.id)
      recipe.value = res.data.recipe
      toast.success(ingredient.name + " added.")
      resetData()
    } catch (err) {
      saving.value = false
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const removeIngredientAtIndex = async (index) => {
    recipe.value.ingredients.splice(index, 1)
    editRecipe()
  }

  const editRecipe = async () => {

    let data = new FormData();
    data.append('recipe', JSON.stringify(recipe.value))

    try {
      saving.value = true
      let res = await RecipeService.updateRecipeIngredients(recipe.value.id, data)
      // let res = await RecipeService.getRecipeWithNutrition(recipe.value.id)
      recipe.value = res.data.recipe
      toast.success(recipe.value.name + " saved.")
      resetData()
    } catch (err) {
      saving.value = false
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  // const petImgUrl = (pet) => {
  //   if (pet.image == null) return '/img/profile_dog_sample.jpg'
  //   else {
  //     console.log('pet image', pet.image.url)
  //     return pet.image.url
  //   }
  // }

  const selPetChanged = async () => {
    router.replace({ name: 'RecipeAdvancedEditForPet', params: { recipeId: recipe.value.id, petId: selPetId.value } })
    loadAll()
  }


  // const recipeTotalGrams = computed({
  //     get: () => Math.round(recipe.value.nutrition_breakdown.total_qty)
  // })

  const getCaPRatio = async () => {
    try {
      let res = await CaPRatioService.getDefault()
      caPRatio.value = res.data.ca_p_ratio
    } catch (err) {
      console.log('Error getting Ca/P Ratio.')
    }
  }

  const rangeLabel = (index) => {
    if (nutrientRanges.value[index] == 1) return 'Low'
    else if (nutrientRanges.value[index] == 2) return 'Optimal'
    else return 'High'
  }

  const rangeClass = (index) => {
    if (nutrientRanges.value[index] == 1) return 'text-yellow-500'
    else if (nutrientRanges.value[index] == 2) return 'text-green-500'
    else return 'text-red-500'
  }

  const caPRatioLabel = () => {
    if (recipeCaPRatio.value <= caPRatio.value.low_max) return 'Low'
    else if (recipeCaPRatio.value >= caPRatio.value.high_min) return 'High'
    else return 'Optimal'
  }

  const caPRatioClass = () => {
    if (recipeCaPRatio.value <= caPRatio.value.low_max) return 'text-yellow-500'
    else if (recipeCaPRatio.value >= caPRatio.value.high_min) return 'text-red-500'
    else return 'text-green-500'
  }

  const ingredientNutrientAmount = (ingredient, index) => {
    let baseline_amount = ingredient.nutrition_breakdown.total_qty
    if (baseline_amount == 0) return 0
    let recipe_amount = ingredient.amount.total_g
    let multiplier = recipe_amount / baseline_amount
    let amount = 0
    try {
      amount = ingredient.nutrition_breakdown.nutrients[index].amount.qty * multiplier
    } catch (error) {
      console.log('trying to get nutrient ingredient', ingredient, index)
      console.log('error')
    }
    return Math.round(amount * 100) / 100
  }

  const ingredientHeaderKey = (ingredient)  => {
    return 'head-'+ingredient.id
  }

  const ingredientTotalKey = (ingredient)  => {
    return 'total-'+ingredient.id
  }

  const ingredientPercentKey = (ingredient)  => {
    return 'percent-'+ingredient.id
  }

  const ingredientGramsDryMatterKey = (ingredient)  => {
    return 'grams-dry-matter-'+ingredient.id
  }

  const recipeNutrientKey = (recipe, nutrient)  => {
    return recipe.id+'-'+nutrient.id
  }

  const ingredientBodyKey = (ingredient)  => {
    return 'body-'+ingredient.id
  }

  const recipeIngredientKey = (recipe, ingredient)  => {
    return recipe.id+'-'+ingredient.id
  }

  const sumIngredientGramsDryMatter = (ingredient) => {
    const totalGrams = parseFloat(ingredient.amount.total_g);
    const waterNutrient = ingredient.nutrition_breakdown.nutrients.find(n => n.name === 'Water');
    
    if (waterNutrient) {
      // not entirely sure what's going on here, used ai and just verified results are correct
      const waterGrams = (waterNutrient.amount.qty / 100) * totalGrams;
      return Math.round((totalGrams - waterGrams) * 100) / 100;
    } else {
      return totalGrams; // Return total grams if water content is not available
    }
  }

  const sumIngredientsGrams = () => {
    let sum = 0
    _.each(recipe.value.ingredients, function (ingredient) {
      sum += parseFloat(ingredient.amount.total_g)
    })
    return sum
  }

  const sumTotalGramsDryMatter = () => {
    return sumIngredientsGrams() - parseFloat(recipe.value.nutrition_breakdown.nutrients.find(n => n.name == 'Water').amount.qty)
  }

  const goToEditRecipe = () => {
    router.replace({ name: 'EditRecipe', params: { id: recipe.value.id } })
  }

  // const getPet = async () => {
  //   try {
  //     let res = await PetService.getPetWithDailyNutrition(route.params.petId)
  //     pet.value = res.data.pet
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }

  // const getRecipe = async () => {
  //   try {
  //     let res = await RecipeService.getRecipe(route.params.recipeId)
  //     recipe.value = res.data.recipe
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }
</script>
